.no-js .hide-when-no-js {
  @apply hidden;
}

h1,
h2,
h3 {
  @apply font-display font-bold #{!important};
}

h4,
h5,
h6 {
  @apply font-body font-bold #{!important};
}

.prose {
  @apply md:prose-lg;

  a {
    @apply text-dt_primary break-words font-semibold hover:text-dt_primary/90;
  }

  // see dragontail_admin.scss .Draftail-block--blockquote for admin style
  blockquote[data-block-key] {
    @apply relative block mt-14 p-5 mx-auto max-w-3xl text-center italic
    bg-dt_primary/10
    before:content-["“"]
    before:absolute
    before:font-serif
    before:top-8
    before:-left-11
    before:leading-[0]
    before:text-dt_primary
    before:text-9xl
    after:content-[""];

    border: none !important;
  }

  code {
    @apply inline-block
    rounded
    px-1.5
    bg-gray-100
    before:hidden
    after:hidden;
  }

  .codehilite pre {
    @apply block max-w-full overflow-x-auto bg-gray-100 p-0;

    code {
      @apply inline-block overflow-x-auto bg-transparent px-5 pt-5 pb-2;
    }
  }

  img {
    @apply max-w-full;
  }

  kbd {
    background: #f8f8f8;
    border-radius: 4px;
    border: 1px solid #dedede;
    box-shadow: 0 1px 0 #dedede, inset 0 0 0 1px #fff;
    font-family: monospace;
    font-size: 0.85rem;
    margin-bottom: 1px;
    padding: 0.1em 0.4em;
    position: relative;
    top: -1px;
    white-space: nowrap;
  }
}

// tables
.d-table-section td {
  > :first-child {
    @apply mt-0 pt-0;
  }

  > :last-child {
    @apply mb-0 pb-0;
  }
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.richtext-image {
  width: auto;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.richtext-image.left,
.richtext-image.right {
  @media (max-width: 639px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.richtext-image.left {
  @media (min-width: 640px) {
    float: left;
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
    max-width: 40%;
  }

  @media (min-width: 768px) {
    justify-content: center;
    max-width: 50%;
  }
}

.richtext-image.right {
  @media (min-width: 640px) {
    float: right;
    padding-left: 1.5rem;
    padding-bottom: 1.5rem;
    max-width: 40%;
  }

  @media (min-width: 768px) {
    justify-content: center;
    max-width: 50%;
  }
}
