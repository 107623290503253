// tailwind
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
// third party
@import "~swiper/css/bundle"; // stimulus-carousel
// local
@import "codehilite";
@import "fonts";
@import "forms";
@import "styles";

.codehilite.codehilite.codehilite {
  @apply bg-transparent;
}

.Draftail-block--HIGHLIGHT.Draftail-block--HIGHLIGHT {
  @apply text-dt_highlight;
}
