td.linenos .normal {
  color: inherit;
  background-color: transparent;
  padding-left: 5px;
  padding-right: 5px;
}

span.linenos {
  color: inherit;
  background-color: transparent;
  padding-left: 5px;
  padding-right: 5px;
}

td.linenos .special {
  color: #000000;
  background-color: #ffffc0;
  padding-left: 5px;
  padding-right: 5px;
}

span.linenos.special {
  color: #000000;
  background-color: #ffffc0;
  padding-left: 5px;
  padding-right: 5px;
}

.codehilite .hll {
  background-color: #ffffcc;
}

.codehilite {
  background: #f8f8f8;
}

.codehilite .c {
  color: #3d7b7b;
  font-style: italic;
}

/* Comment */
.codehilite .err {
  border: 1px solid #ff0000;
}

/* Error */
.codehilite .k {
  color: #008000;
  font-weight: bold;
}

/* Keyword */
.codehilite .o {
  color: #666666;
}

/* Operator */
.codehilite .ch {
  color: #3d7b7b;
  font-style: italic;
}

/* Comment.Hashbang */
.codehilite .cm {
  color: #3d7b7b;
  font-style: italic;
}

/* Comment.Multiline */
.codehilite .cp {
  color: #9c6500;
}

/* Comment.Preproc */
.codehilite .cpf {
  color: #3d7b7b;
  font-style: italic;
}

/* Comment.PreprocFile */
.codehilite .c1 {
  color: #3d7b7b;
  font-style: italic;
}

/* Comment.Single */
.codehilite .cs {
  color: #3d7b7b;
  font-style: italic;
}

/* Comment.Special */
.codehilite .gd {
  color: #a00000;
}

/* Generic.Deleted */
.codehilite .ge {
  font-style: italic;
}

/* Generic.Emph */
.codehilite .gr {
  color: #e40000;
}

/* Generic.Error */
.codehilite .gh {
  color: #000080;
  font-weight: bold;
}

/* Generic.Heading */
.codehilite .gi {
  color: #008400;
}

/* Generic.Inserted */
.codehilite .go {
  color: #717171;
}

/* Generic.Output */
.codehilite .gp {
  color: #000080;
  font-weight: bold;
}

/* Generic.Prompt */
.codehilite .gs {
  font-weight: bold;
}

/* Generic.Strong */
.codehilite .gu {
  color: #800080;
  font-weight: bold;
}

/* Generic.Subheading */
.codehilite .gt {
  color: #0044dd;
}

/* Generic.Traceback */
.codehilite .kc {
  color: #008000;
  font-weight: bold;
}

/* Keyword.Constant */
.codehilite .kd {
  color: #008000;
  font-weight: bold;
}

/* Keyword.Declaration */
.codehilite .kn {
  color: #008000;
  font-weight: bold;
}

/* Keyword.Namespace */
.codehilite .kp {
  color: #008000;
}

/* Keyword.Pseudo */
.codehilite .kr {
  color: #008000;
  font-weight: bold;
}

/* Keyword.Reserved */
.codehilite .kt {
  color: #b00040;
}

/* Keyword.Type */
.codehilite .m {
  color: #666666;
}

/* Literal.Number */
.codehilite .s {
  color: #ba2121;
}

/* Literal.String */
.codehilite .na {
  color: #687822;
}

/* Name.Attribute */
.codehilite .nb {
  color: #008000;
}

/* Name.Builtin */
.codehilite .nc {
  color: #0000ff;
  font-weight: bold;
}

/* Name.Class */
.codehilite .no {
  color: #880000;
}

/* Name.Constant */
.codehilite .nd {
  color: #aa22ff;
}

/* Name.Decorator */
.codehilite .ni {
  color: #717171;
  font-weight: bold;
}

/* Name.Entity */
.codehilite .ne {
  color: #cb3f38;
  font-weight: bold;
}

/* Name.Exception */
.codehilite .nf {
  color: #0000ff;
}

/* Name.Function */
.codehilite .nl {
  color: #767600;
}

/* Name.Label */
.codehilite .nn {
  color: #0000ff;
  font-weight: bold;
}

/* Name.Namespace */
.codehilite .nt {
  color: #008000;
  font-weight: bold;
}

/* Name.Tag */
.codehilite .nv {
  color: #19177c;
}

/* Name.Variable */
.codehilite .ow {
  color: #aa22ff;
  font-weight: bold;
}

/* Operator.Word */
.codehilite .w {
  color: #bbbbbb;
}

/* Text.Whitespace */
.codehilite .mb {
  color: #666666;
}

/* Literal.Number.Bin */
.codehilite .mf {
  color: #666666;
}

/* Literal.Number.Float */
.codehilite .mh {
  color: #666666;
}

/* Literal.Number.Hex */
.codehilite .mi {
  color: #666666;
}

/* Literal.Number.Integer */
.codehilite .mo {
  color: #666666;
}

/* Literal.Number.Oct */
.codehilite .sa {
  color: #ba2121;
}

/* Literal.String.Affix */
.codehilite .sb {
  color: #ba2121;
}

/* Literal.String.Backtick */
.codehilite .sc {
  color: #ba2121;
}

/* Literal.String.Char */
.codehilite .dl {
  color: #ba2121;
}

/* Literal.String.Delimiter */
.codehilite .sd {
  color: #ba2121;
  font-style: italic;
}

/* Literal.String.Doc */
.codehilite .s2 {
  color: #ba2121;
}

/* Literal.String.Double */
.codehilite .se {
  color: #aa5d1f;
  font-weight: bold;
}

/* Literal.String.Escape */
.codehilite .sh {
  color: #ba2121;
}

/* Literal.String.Heredoc */
.codehilite .si {
  color: #a45a77;
  font-weight: bold;
}

/* Literal.String.Interpol */
.codehilite .sx {
  color: #008000;
}

/* Literal.String.Other */
.codehilite .sr {
  color: #a45a77;
}

/* Literal.String.Regex */
.codehilite .s1 {
  color: #ba2121;
}

/* Literal.String.Single */
.codehilite .ss {
  color: #19177c;
}

/* Literal.String.Symbol */
.codehilite .bp {
  color: #008000;
}

/* Name.Builtin.Pseudo */
.codehilite .fm {
  color: #0000ff;
}

/* Name.Function.Magic */
.codehilite .vc {
  color: #19177c;
}

/* Name.Variable.Class */
.codehilite .vg {
  color: #19177c;
}

/* Name.Variable.Global */
.codehilite .vi {
  color: #19177c;
}

/* Name.Variable.Instance */
.codehilite .vm {
  color: #19177c;
}

/* Name.Variable.Magic */
.codehilite .il {
  color: #666666;
}

/* Literal.Number.Integer.Long */
