@font-face {
  font-family: Lora;
  font-style: normal;
  font-weight: 500;
  src: local(""),
    url("../../assets/fonts/lora-v26-latin-500.woff2") format("woff2"),
    url("../../assets/fonts/lora-v26-latin-500.woff") format("woff");
}

// Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options.
@font-face {
  font-display: swap;
  font-family: "Fredericka the Great";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("../../assets/fonts/fredericka-the-great-v15-latin-regular.woff2")
      format("woff2"),
    url("../../assets/fonts/fredericka-the-great-v15-latin-regular.woff")
      format("woff");
}
