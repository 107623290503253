.d-form {
  @apply container max-w-4xl py-5 px-7 rounded-lg;

  .d-fieldset {
    @apply container w-full my-3 mx-auto
      flex flex-col gap-3
      first-of-type:mt-0 last-of-type:mb-0;
  }

  .d-fieldset-errors {
    @apply empty:hidden prose bg-red-50 border-2 border-red-500 rounded py-4 px-7 mb-7;

    ul {
      @apply m-0 p-0 marker:text-red-500;
    }
  }

  .d-fieldset-label {
    @apply container w-full max-w-full
      flex flex-wrap justify-between items-start;
  }

  .d-fieldset-label-text {
    @apply text-dt_body_text font-semibold text-sm sm:text-base md:text-xl;
  }

  .d-fieldset-label-info {
    @apply text-dt_body_text block pl-3 text-sm sm:text-base md:text-xl;
  }

  .d-fieldset-field {
    @apply container w-full;

    input,
    select,
    textarea {
      @apply block w-full my-2
        p-2
        shadow-sm rounded-md border-2 border-dt_primary
        text-dt_body_text text-base
        focus:outline-offset-0 focus:outline-dt_primary;
    }

    input[type="checkbox"] {
      width: fit-content;
    }
  }

  .d-fieldset-help-text {
    @apply text-sm sm:text-base md:text-xl;
  }

  .d-form-submit[type="submit"] {
    @apply flex justify-self-center self-center py-2 px-4 my-3 mx-auto
      shadow-sm border border-transparent rounded-md cursor-pointer
      text-base sm:text-xl md:text-2xl font-medium
      text-dt_button_text_primary
      bg-dt_button_bg_primary hover:bg-dt_button_bg_primary/95;
  }

  input[data-whf_name] {
    @apply absolute top-0 left-0 -ml-[100vw];
  }
}
